import { openCageKey } from '../constants/apiConstants';

function useGeocoding() {
  const getCoordinates = async (address) => {
    try {
      if (!address) {
        throw new Error('Address is required');
      }

      const geoResponse = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          address
        )}&key=${openCageKey}&limit=1`
      );

      if (!geoResponse.ok) {
        throw new Error('Geocoding API request failed');
      }

      const geoData = await geoResponse.json();
      
      if (!geoData.results || geoData.results.length === 0) {
        throw new Error(`No location found for address: ${address}`);
      }

      const { lat, lng } = geoData.results[0].geometry;
      return { lat, lng };
    } catch (error) {
      console.error('Geocoding error:', error);
      throw new Error(`Geocoding failed for address "${address}": ${error.message}`);
    }
  };

  const getAddressSuggestions = async (input) => {
    try {
      const response = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(input)}&key=${openCageKey}&limit=5&autocomplete=1`
      );
      const data = await response.json();
      return data.results.map(result => result.formatted);
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
      return [];
    }
  };

  return { getCoordinates, getAddressSuggestions };
}

export default useGeocoding;