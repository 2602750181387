export const generateHailReport = (asset, hailData) => {
  const { noaa, radar, sightings } = hailData;
  
  // Combine all events and sort by date
  const allEvents = [
    ...noaa.map(event => ({ ...event, source: 'NOAA' })),
    ...radar.map(event => ({ ...event, source: 'Radar' })),
    ...sightings.map(event => ({ ...event, source: 'Sightings' }))
  ].sort((a, b) => new Date(b.BEGIN_DATE_TIME) - new Date(a.BEGIN_DATE_TIME));

  // Calculate statistics - exclude radar data for largest hail
  const spottedEvents = allEvents.filter(event => event.source !== 'Radar');
  const validHailSizes = spottedEvents.map(event => 
    parseFloat(event.MAGNITUDE || event.MAXSIZE || event.SizeInInches || 0)
  ).filter(size => !isNaN(size) && size > 0);

  const stats = {
    totalEvents: allEvents.length,
    largestSpottedHail: validHailSizes.length > 0 ? Math.max(...validHailSizes) : 'N/A',
    eventsBySource: {
      NOAA: noaa.length,
      Radar: radar.length,
      Sightings: sightings.length
    }
  };

  return {
    assetInfo: asset,
    statistics: stats,
    events: allEvents
  };
};

export const formatReportDate = (date) => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\//g, '.');
};
