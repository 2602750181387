import { useState, useEffect, useCallback } from 'react';
import useGeocoding from './useGeocoding';

function useStormEvents(supabase) {
  const [address, setAddress] = useState('');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [radius, setRadius] = useState(1609.34);
  const [yearsBack, setYearsBack] = useState(1);
  const [error, setError] = useState(null);
  const [dataSource, setDataSource] = useState('NOAA');

  const { getCoordinates } = useGeocoding();

  useEffect(() => {
    // Clear events when dataSource changes
    setEvents([]);
    setTotalCount(0);
  }, [dataSource]);

  const fetchNOAAData = useCallback(async (params) => {
    const { data, error, count } = await supabase.rpc('get_storm_events_nearby', params)
      .range(0, 999)
      .select('*', { count: 'exact' });

    if (error) throw error;
    setEvents(data);
    setTotalCount(count);
  }, [supabase]);

  const fetchHailSightings = useCallback(async (lat, lng, startDate, endDate, radius) => {
    // Convert radius from meters to degrees (approximate)
    const radiusDegrees = radius / 111000; // 1 degree is approximately 111 km

    const { data, error, count } = await supabase
      .from('dailyhailsitings')
      .select('*', { count: 'exact' })
      .filter('Lat', 'gte', lat - radiusDegrees)
      .filter('Lat', 'lte', lat + radiusDegrees)
      .filter('Lon', 'gte', lng - radiusDegrees)
      .filter('Lon', 'lte', lng + radiusDegrees)
      .gte('date', startDate.toISOString().split('T')[0])
      .lte('date', endDate.toISOString().split('T')[0])
      .limit(1000);
    
    if (error) throw error;
    
    const processedData = data.map(item => ({
      ...item,
      SizeInInches: item.Size ? (parseFloat(item.Size) / 100).toFixed(2) : 'N/A',
      BEGIN_DATE_TIME: combineDateTime(item.date, item.Time)
    }));

    // Apply Haversine formula to filter results within the exact radius
    const filteredData = processedData.filter(item => 
      calculateDistance(lat, lng, item.Lat, item.Lon) <= radius / 1609.34 // Convert meters to miles
    );

    setEvents(filteredData);
    setTotalCount(filteredData.length);
  }, [supabase]);

  const fetchRadarData = useCallback(async (lat, lng, startDate, endDate, radius) => {
    const radiusDegrees = radius / 111000;

    const { data, error, count } = await supabase
      .from('noaa_hail_radar_sm')
      .select('*', { count: 'exact' })
      .filter('LAT', 'gte', lat - radiusDegrees)
      .filter('LAT', 'lte', lat + radiusDegrees)
      .filter('LON', 'gte', lng - radiusDegrees)
      .filter('LON', 'lte', lng + radiusDegrees)
      .gte('ZTIME', startDate.toISOString())
      .lte('ZTIME', endDate.toISOString())
      .limit(1000);

    if (error) throw error;

    // Debug log to see raw data
    console.log('Raw radar data:', data[0]); // Look at first record

    const normalizedData = data.map(item => {
      const distance = calculateDistance(lat, lng, item.LAT, item.LON);
      const direction = calculateDirection(lat, lng, item.LAT, item.LON);

      // Debug log to see item values
      console.log('Radar item MAXSIZE:', item.MAXSIZE);
      
      const normalized = {
        ...item,
        BEGIN_LAT: item.LAT,
        BEGIN_LON: item.LON,
        BEGIN_DATE_TIME: item.ZTIME,
        MAGNITUDE: item.MAXSIZE, // Simplified - remove template literal
        MAGNITUDE_TYPE: 'inches',
        EVENT_TYPE: 'Radar Detected Hail',
        EVENT_NARRATIVE: `WSR ID: ${item.WSR_ID}, Cell ID: ${item.CELL_ID}, Probability: ${item.PROB}%, Severity: ${item.SEVPROB}%`,
        SOURCE: 'Radar',
        DISTANCE: distance.toFixed(2),
        DIRECTION: direction
      };

      // Debug log normalized item
      console.log('Normalized item:', normalized);
      
      return normalized;
    });

    setEvents(normalizedData);
    setTotalCount(count);
  }, [supabase]);

  const combineDateTime = (date, time) => {
    if (!date) return null;
    
    let timeString = '00:00:00'; // Default time if not provided
    
    if (time) {
      // Parse the time format "1907" into "19:07:00"
      if (time.length === 4) {
        const hours = time.slice(0, 2);
        const minutes = time.slice(2, 4);
        timeString = `${hours}:${minutes}:00`;
      } else {
        console.warn(`Unexpected time format: ${time}`);
      }
    }
    
    // Combine date and time
    const combinedDateTime = `${date}T${timeString}`;
    
    // Validate the combined date-time
    const dateObj = new Date(combinedDateTime);
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid date-time:', combinedDateTime);
      return null;
    }
    
    return combinedDateTime;
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c; // Distance in km
    return d * 0.621371; // Convert to miles
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI/180)
  };

  const calculateDirection = (lat1, lon1, lat2, lon2) => {
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const lat1Rad = lat1 * (Math.PI / 180);
    const lat2Rad = lat2 * (Math.PI / 180);
    
    const y = Math.sin(dLon) * Math.cos(lat2Rad);
    const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) -
             Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(dLon);
    
    let bearing = Math.atan2(y, x) * (180 / Math.PI);
    bearing = (bearing + 360) % 360;
    
    // Convert bearing to cardinal direction
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    const index = Math.round(bearing / 45) % 8;
    return directions[index];
  };

  const handleSearch = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { lat, lng } = await getCoordinates(address);
      setCoordinates({ lat, lng });

      const endDate = new Date();
      const startDate = new Date();
      startDate.setFullYear(endDate.getFullYear() - yearsBack);

      if (dataSource === 'NOAA') {
        await fetchNOAAData({
          lat,
          lon: lng,
          radius: parseFloat(radius),
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString()
        });
      } else if (dataSource === 'Radar') {
        await fetchRadarData(lat, lng, startDate, endDate, parseFloat(radius));
      } else {
        await fetchHailSightings(lat, lng, startDate, endDate, parseFloat(radius));
      }
    } catch (error) {
      setError('An error occurred while fetching data. Please try again later.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, [address, yearsBack, radius, dataSource, getCoordinates, fetchNOAAData, fetchRadarData, fetchHailSightings]);

  // Trigger search when dataSource changes
  useEffect(() => {
    if (coordinates) {
      handleSearch();
    }
  }, [dataSource]);

  const fetchHailDataForLocation = useCallback(async (lat, lng, startDate, endDate, radius, lastUpdate = null) => {
    try {
      // 1. Fetch NOAA data
      let noaaQuery = supabase.rpc('get_storm_events_nearby', {
        lat,
        lon: lng,
        radius: parseFloat(radius),
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString()
      }).select('*');

      // Add updated_on filter if lastUpdate is provided
      if (lastUpdate) {
        noaaQuery = noaaQuery.gt('updated_on', lastUpdate);
      }

      const { data: noaaData } = await noaaQuery;

      // 2. Fetch Radar data
      const radiusDegrees = radius / 111000;
      let radarQuery = supabase
        .from('noaa_hail_radar_sm')
        .select('*')
        .filter('LAT', 'gte', lat - radiusDegrees)
        .filter('LAT', 'lte', lat + radiusDegrees)
        .filter('LON', 'gte', lng - radiusDegrees)
        .filter('LON', 'lte', lng + radiusDegrees)
        .gte('ZTIME', startDate.toISOString())
        .lte('ZTIME', endDate.toISOString());

      if (lastUpdate) {
        radarQuery = radarQuery.gt('updated_on', lastUpdate);
      }

      const { data: radarData } = await radarQuery;

      // 3. Fetch Sightings data
      let sightingsQuery = supabase
        .from('dailyhailsitings')
        .select('*')
        .filter('Lat', 'gte', lat - radiusDegrees)
        .filter('Lat', 'lte', lat + radiusDegrees)
        .filter('Lon', 'gte', lng - radiusDegrees)
        .filter('Lon', 'lte', lng + radiusDegrees)
        .gte('date', startDate.toISOString().split('T')[0])
        .lte('date', endDate.toISOString().split('T')[0]);

      if (lastUpdate) {
        sightingsQuery = sightingsQuery.gt('updated_on', lastUpdate);
      }

      const { data: sightingsData } = await sightingsQuery;

      // Process and normalize the data
      const processedRadarData = (radarData || []).map(item => ({
        ...item,
        BEGIN_DATE_TIME: item.ZTIME,
        MAGNITUDE: item.MAXSIZE,
        source: 'Radar'
      }));

      const processedSightingsData = (sightingsData || []).map(item => ({
        ...item,
        BEGIN_DATE_TIME: combineDateTime(item.date, item.Time),
        MAGNITUDE: (parseFloat(item.Size) / 100).toFixed(2),
        source: 'Sightings'
      }));

      return {
        noaa: noaaData || [],
        radar: processedRadarData,
        sightings: processedSightingsData
      };
    } catch (error) {
      console.error('Error fetching hail data:', error);
      throw error;
    }
  }, [supabase]);

  return {
    address,
    setAddress,
    coordinates,
    events,
    totalCount,
    loading,
    error,
    dataSource,
    setDataSource,
    handleSearch,
    radius,
    setRadius,
    yearsBack,
    setYearsBack,
    getCoordinates, // Ensure getCoordinates is exported
    fetchHailDataForLocation,
  };
}

export default useStormEvents;