import React, { useState } from 'react';
import Papa from 'papaparse';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';
import useGeocoding from '../../hooks/useGeocoding';

const supabase = createClient(supabaseUrl, supabaseKey);

function CsvUpload({ selectedClientId, onUpload }) {
  const [csvFile, setCsvFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { getCoordinates } = useGeocoding();

  const handleFileChange = (e) => {
    setCsvFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!csvFile || !selectedClientId) return;
    setIsUploading(true);

    Papa.parse(csvFile, {
      header: true,
      complete: async (results) => {
        try {
          const assets = results.data.filter(asset => asset.address && asset.city && asset.state);
          const assetsWithCoordinates = await Promise.all(
            assets.map(async (asset) => {
              const fullAddress = `${asset.address}, ${asset.city}, ${asset.state}`;
              const { lat, lng } = await getCoordinates(fullAddress);
              return { 
                ...asset, 
                client_id: selectedClientId, 
                lat, 
                lng 
              };
            })
          );

          const { data, error } = await supabase
            .from('assets')
            .insert(assetsWithCoordinates)
            .select();

          if (error) throw error;

          onUpload(data || []);
          setCsvFile(null);
        } catch (error) {
          console.error('Error uploading assets:', error);
          alert('Error uploading assets: ' + error.message);
        } finally {
          setIsUploading(false);
        }
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
        alert('Error parsing CSV file');
        setIsUploading(false);
      },
    });
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleFileChange} disabled={isUploading} />
      <button onClick={handleUpload} disabled={!csvFile || isUploading}>
        {isUploading ? 'Uploading...' : 'Upload CSV'}
      </button>
    </div>
  );
}

export default CsvUpload;