import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';
import CsvUpload from './CsvUpload';
import useStormEvents from '../../hooks/useStormEvents';
import './ClientManager.css'; // Import the CSS file

const supabase = createClient(supabaseUrl, supabaseKey);

// Add this new component at the top of the file
const DeleteConfirmationDialog = ({ isOpen, business_name, onConfirm, onCancel }) => {
  const [confirmText, setConfirmText] = useState('');

  if (!isOpen) return null;

  return (
    <div className="delete-confirmation-overlay">
      <div className="delete-confirmation-dialog">
        <h3>Delete Confirmation</h3>
        <p>To confirm deletion, please type: <strong>{business_name}</strong></p>
        <input
          type="text"
          value={confirmText}
          onChange={(e) => setConfirmText(e.target.value)}
          placeholder="Type business name to confirm"
        />
        <div className="dialog-buttons">
          <button 
            onClick={() => onConfirm(confirmText)}
            className="confirm-button"
          >
            Delete
          </button>
          <button 
            onClick={onCancel}
            className="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default function ClientManager() {
  const [clients, setClients] = useState([]);
  const [assets, setAssets] = useState([]);
  const [newClient, setNewClient] = useState({
    business_name: '',
    website: '',
    mailing_address: '',
    contact_name: '',
    contact_email: '',
    contact_phone: ''
  });
  const [newAsset, setNewAsset] = useState({ address: '', report_url: '', city: '', state: '' });
  const [selectedClient, setSelectedClient] = useState(null);
  const [showAddClientForm, setShowAddClientForm] = useState(false);
  const [showAddAssetForm, setShowAddAssetForm] = useState(false);
  const [showUploadCsvForm, setShowUploadCsvForm] = useState(false);
  const [assetPage, setAssetPage] = useState(1);
  const assetPageSize = 10;
  const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, clientId: null, businessName: '' });

  const { getCoordinates } = useStormEvents(supabase);

  useEffect(() => {
    fetchClients();
    fetchAssets();
  }, []);

  const fetchClients = async () => {
    const { data, error } = await supabase.from('clients').select('*');
    if (error) {
      console.error('Error fetching clients:', error);
    } else {
      setClients(data || []);
    }
  };

  const fetchAssets = async () => {
    const { data, error } = await supabase.from('assets').select('*');
    if (error) {
      console.error('Error fetching assets:', error);
    } else {
      setAssets(data || []);
    }
  };

  const addClient = async () => {
    const { error } = await supabase.from('clients').insert([newClient]);
    if (error) {
      console.error('Error adding client:', error);
    } else {
      // Fetch the updated clients list
      await fetchClients();
      setNewClient({
        business_name: '',
        website: '',
        mailing_address: '',
        contact_name: '',
        contact_email: '',
        contact_phone: ''
      });
      setShowAddClientForm(false);
    }
  };

  const addAsset = async () => {
    if (!selectedClient) return;
    try {
      const { lat, lng } = await getCoordinates(newAsset.address);
      const assetWithCoordinates = { ...newAsset, client_id: selectedClient, lat, lng };
      const { error } = await supabase.from('assets').insert([assetWithCoordinates]);
      if (error) {
        console.error('Error adding asset:', error);
      } else {
        // Fetch the updated assets list
        const { data: updatedAssets, error: fetchError } = await supabase.from('assets').select('*');
        if (!fetchError) {
          setAssets(updatedAssets || []);
          setNewAsset({ address: '', report_url: '', city: '', state: '' });
          setShowAddAssetForm(false);
        }
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };

  const deleteClient = async (clientId, event) => {
    event.stopPropagation();
    const client = clients.find(c => c.id === clientId);
    setDeleteConfirmation({ 
      isOpen: true, 
      clientId, 
      businessName: client.business_name 
    });
  };

  const handleDeleteConfirm = async (confirmText) => {
    const { clientId, businessName } = deleteConfirmation;
    
    if (confirmText !== businessName) {
      alert("Business name doesn't match. Deletion cancelled.");
      setDeleteConfirmation({ isOpen: false, clientId: null, businessName: '' });
      return;
    }

    try {
      const { error: assetsError } = await supabase
        .from('assets')
        .delete()
        .eq('client_id', clientId);

      if (assetsError) throw assetsError;

      const { error: clientError } = await supabase
        .from('clients')
        .delete()
        .eq('id', clientId);

      if (clientError) throw clientError;

      setAssets(assets.filter(asset => asset.client_id !== clientId));
      setClients(clients.filter(client => client.id !== clientId));
      
      if (selectedClient === clientId) {
        setSelectedClient(null);
      }
    } catch (error) {
      console.error('Error deleting client:', error);
    }
    
    setDeleteConfirmation({ isOpen: false, clientId: null, businessName: '' });
  };

  const deleteAsset = async (assetId) => {
    if (window.confirm('Are you sure you want to delete this asset?')) {
      const { error } = await supabase.from('assets').delete().eq('id', assetId);
      if (error) {
        console.error('Error deleting asset:', error);
      } else {
        setAssets(assets.filter(asset => asset.id !== assetId));
      }
    }
  };

  const handleClientSelect = (clientId) => {
    setSelectedClient(clientId);
    setShowAddAssetForm(false);
    setShowUploadCsvForm(false);
  };

  const handleCsvUpload = (uploadedAssets) => {
    setAssets([...assets, ...uploadedAssets]);
  };

  const filteredAssets = selectedClient
    ? assets.filter((asset) => asset.client_id === selectedClient)
    : [];

  const paginatedAssets = filteredAssets.slice((assetPage - 1) * assetPageSize, assetPage * assetPageSize);

  return (
    <div className="client-manager">
      <h2>Client Manager</h2>

      <section>
        <h3 className="table-title">
          <button onClick={() => setShowAddClientForm(!showAddClientForm)}>
            {showAddClientForm ? 'Cancel' : 'Add New Client'}
          </button>
        </h3>
        {showAddClientForm && (
          <div>
            <input
              type="text"
              placeholder="Business Name"
              value={newClient.business_name}
              onChange={(e) => setNewClient({ ...newClient, business_name: e.target.value })}
            />
            <input
              type="text"
              placeholder="Website"
              value={newClient.website}
              onChange={(e) => setNewClient({ ...newClient, website: e.target.value })}
            />
            <input
              type="text"
              placeholder="Mailing Address"
              value={newClient.mailing_address}
              onChange={(e) => setNewClient({ ...newClient, mailing_address: e.target.value })}
            />
            <input
              type="text"
              placeholder="Contact Name"
              value={newClient.contact_name}
              onChange={(e) => setNewClient({ ...newClient, contact_name: e.target.value })}
            />
            <input
              type="email"
              placeholder="Contact Email"
              value={newClient.contact_email}
              onChange={(e) => setNewClient({ ...newClient, contact_email: e.target.value })}
            />
            <input
              type="text"
              placeholder="Contact Phone"
              value={newClient.contact_phone}
              onChange={(e) => setNewClient({ ...newClient, contact_phone: e.target.value })}
            />
            <button onClick={addClient}>Add Client</button>
          </div>
        )}
      </section>

      <section>
        <h3 className="table-title">Clients</h3>
        <table>
          <thead>
            <tr>
              <th>Business Name</th>
              <th>Contact Name</th>
              <th>Contact Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr
                key={client.id}
                onClick={() => handleClientSelect(client.id)}
                className={selectedClient === client.id ? 'selected-row' : ''}
              >
                <td data-label="Business Name">{client.business_name}</td>
                <td data-label="Contact Name">{client.contact_name}</td>
                <td data-label="Contact Email">{client.contact_email}</td>
                <td data-label="Actions">
                  <span 
                    className="action-link" 
                    style={{ color: 'red' }} 
                    onClick={(e) => deleteClient(client.id, e)}
                  >
                    Delete
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section>
        <h3 className="table-title">Assets</h3>
        {selectedClient && (
          <div>
            <button onClick={() => setShowAddAssetForm(!showAddAssetForm)}>
              {showAddAssetForm ? 'Cancel' : 'Add New Asset'}
            </button>
            <button onClick={() => setShowUploadCsvForm(!showUploadCsvForm)}>
              {showUploadCsvForm ? 'Cancel' : 'Upload CSV'}
            </button>
          </div>
        )}
        {showAddAssetForm && selectedClient && (
          <div style={{ marginTop: '20px' }}>
            <input
              type="text"
              placeholder="Address"
              value={newAsset.address}
              onChange={(e) => setNewAsset({ ...newAsset, address: e.target.value })}
            />
            <input
              type="text"
              placeholder="City"
              value={newAsset.city}
              onChange={(e) => setNewAsset({ ...newAsset, city: e.target.value })}
            />
            <input
              type="text"
              placeholder="State"
              value={newAsset.state}
              onChange={(e) => setNewAsset({ ...newAsset, state: e.target.value })}
            />
            <input
              type="text"
              placeholder="Report URL"
              value={newAsset.report_url}
              onChange={(e) => setNewAsset({ ...newAsset, report_url: e.target.value })}
            />
            <button onClick={addAsset}>Add Asset</button>
          </div>
        )}
        {showUploadCsvForm && selectedClient && (
          <div style={{ marginTop: '20px' }}>
            <CsvUpload selectedClientId={selectedClient} onUpload={handleCsvUpload} />
          </div>
        )}
        <table style={{ marginTop: '20px' }}>
          <thead>
            <tr>
              <th>Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedAssets.map((asset) => (
              <tr key={asset.id}>
                <td data-label="Address">{`${asset.address}, ${asset.city}, ${asset.state}`}</td>
                <td data-label="Actions">
                  <span className="action-link" style={{ color: 'red' }} onClick={() => deleteAsset(asset.id)}>Delete</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {selectedClient && paginatedAssets.length > 0 && (
          <div className="pagination">
            <button
              onClick={() => setAssetPage(assetPage - 1)}
              disabled={assetPage === 1}
            >
              Previous
            </button>
            <span style={{ margin: '0 10px' }}>Page {assetPage}</span>
            <button
              onClick={() => setAssetPage(assetPage + 1)}
              disabled={paginatedAssets.length < assetPageSize}
            >
              Next
            </button>
          </div>
        )}
      </section>
      
      <DeleteConfirmationDialog
        isOpen={deleteConfirmation.isOpen}
        business_name={deleteConfirmation.businessName}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setDeleteConfirmation({ isOpen: false, clientId: null, businessName: '' })}
      />
    </div>
  );
}