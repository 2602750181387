import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import StormEvents from './components/StormEvents/StormEvents';
import ClientManager from './components/ClientManager/ClientManager';
import ReportGenerator from './components/Reports/ReportGenerator';
import PasswordForm from './components/Authentication/PasswordForm';
import { AuthProvider, useAuth } from './context/AuthContext';
import AdminConfig from './components/Admin/AdminConfig';
import './App.css';
import logo from './assets/images/logo.webp'; // Add this import

function AppContent() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <PasswordForm onAuthenticate={() => setIsAuthenticated(true)} />;
  }

  return (
    <div className="App">
      <div className="logo-container">
        <img src={logo} alt="Company Logo" className="app-logo" />
        <div className="logo-text">Guardian</div>
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/">Hail Search</Link>
          </li>
          <li>
            <Link to="/clients">Client Manager</Link>
          </li>
          <li>
            <Link to="/reports">Report Generator</Link>
          </li>
          <li>
            <Link to="/auto-report">Auto Report</Link>
          </li>
        </ul>
      </nav>
      <main>
        <Routes>
          <Route path="/" element={<StormEvents />} />
          <Route path="/clients" element={<ClientManager />} />
          <Route path="/reports" element={<ReportGenerator />} />
          <Route path="/auto-report" element={<AdminConfig />} />
        </Routes>
      </main>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
}

export default App;