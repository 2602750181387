import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { calculateDistance, calculateBearing, getDirection } from '../../utils/distanceUtils';

function EventsTable({ events = [], totalCount, coordinates, dataSource }) {
  const columns = useMemo(
    () => [

      {
        Header: 'Date & Time',
        accessor: 'BEGIN_DATE_TIME',
        Cell: ({ value, row }) => {
          console.log('Date & Time value:', value);
          console.log('Full row data:', row.original);
          if (!value) return 'N/A';
          const date = new Date(value);
          console.log('Parsed date:', date);
          const formattedDate = !isNaN(date.getTime()) ? date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }) : 'Invalid Date';
          console.log('Formatted date:', formattedDate);
          return formattedDate;
        },
      },
      {
        Header: 'Size',
        accessor: row => {
          if (dataSource === 'Radar') {
            return `${row.MAXSIZE || 'N/A'} inches`;
          } else if (dataSource === 'NOAA') {
            return `${row.MAGNITUDE || 'N/A'} ${row.MAGNITUDE_TYPE || ''}`;
          } else {
            return `${row.SizeInInches || 'N/A'} inches`;
          }
        },
      },
      {
        Header: 'Distance',
        accessor: row => {
          if (!coordinates) return 'N/A';
          
          // Handle different data sources
          const lat = dataSource === 'Radar' ? row.LAT : 
                     dataSource === 'NOAA' ? row.BEGIN_LAT : 
                     row.Lat;
          
          const lon = dataSource === 'Radar' ? row.LON : 
                     dataSource === 'NOAA' ? row.BEGIN_LON : 
                     row.Lon;

          if (lat == null || lon == null) return 'N/A';
          return calculateDistance(coordinates.lat, coordinates.lng, lat, lon);
        },
        Cell: ({ value }) => typeof value === 'number' ? `${value.toFixed(2)} miles` : value,
      },
      {
        Header: 'Direction',
        accessor: row => {
          if (!coordinates) return 'N/A';
          
          // Handle different data sources
          const lat = dataSource === 'Radar' ? row.LAT : 
                     dataSource === 'NOAA' ? row.BEGIN_LAT : 
                     row.Lat;
          
          const lon = dataSource === 'Radar' ? row.LON : 
                     dataSource === 'NOAA' ? row.BEGIN_LON : 
                     row.Lon;

          if (lat == null || lon == null) return 'N/A';
          const bearing = calculateBearing(coordinates.lat, coordinates.lng, lat, lon);
          return getDirection(bearing);
        },
      },
      ...(dataSource === 'NOAA' ? [{
        Header: 'Source', // Add Source column only for NOAA
        accessor: 'SOURCE',
        Cell: ({ value }) => value || 'N/A',
      }] : []),
      {
        Header: 'Comment',
        accessor: row => {
          if (dataSource === 'Radar') {
            return `Severity: ${row.SEVPROB}%, Probability: ${row.PROB}%`;
          } else if (dataSource === 'NOAA') {
            return row.EVENT_NARRATIVE;
          } else {
            return row.Comments;
          }
        },
        Cell: ({ value }) => value || 'N/A',
      },
    ],
    [dataSource, coordinates]
  );

  const data = useMemo(() => events || [], [events]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useSortBy);

  if (rows.length === 0) {
    return <p>No events to display.</p>;
  }

  return (
    <div class='table-wrapper'>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse'}}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                  borderBottom: 'solid 3px red',
                  background: 'aliceblue',
                  color: 'black',
                  fontWeight: 'bold',
                  padding: '10px',
                }}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        border: 'solid 1px gray',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <p>Showing {rows.length} of {totalCount} events</p>
    </div>
  );
}

export default EventsTable;