import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';
import useStormEvents from '../../hooks/useStormEvents';
import { generateHailReport, formatReportDate } from '../../utils/reportUtils';
import { CSVDownload } from 'react-csv';
import './ReportGenerator.css';
import '../../utils/reportUtils.css';

const supabase = createClient(supabaseUrl, supabaseKey);

function ReportGenerator() {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    end: new Date().toISOString().split('T')[0]
  });
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [radius, setRadius] = useState(16093.4); // 10 miles in meters
  const [timeFrame, setTimeFrame] = useState({
    unit: 'years',
    value: 2
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSources, setSelectedSources] = useState({
    sightings: true,
    noaa: true,
    radar: false
  });

  const { fetchHailDataForLocation } = useStormEvents(supabase);

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    const endDate = new Date();
    const startDate = new Date();
    
    if (timeFrame.unit === 'years') {
      startDate.setFullYear(endDate.getFullYear() - timeFrame.value);
    } else {
      startDate.setDate(endDate.getDate() - timeFrame.value);
    }
    
    setDateRange({
      start: startDate.toISOString().split('T')[0],
      end: endDate.toISOString().split('T')[0]
    });
  }, [timeFrame]);

  const fetchClients = async () => {
    const { data } = await supabase.from('clients').select('*');
    setClients(data || []);
  };

  const fetchAssets = async (clientId) => {
    const { data } = await supabase
      .from('assets')
      .select('*')
      .eq('client_id', clientId);
    setSelectedAssets(data || []);
  };

  const handleClientSelect = (clientId) => {
    setSelectedClient(clientId);
    fetchAssets(clientId);
  };

  // Filter clients based on search term
  const filteredClients = clients.filter(client => 
    client.business_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle client search
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  // Handle client selection from suggestions
  const handleSuggestionClick = (client) => {
    setSearchTerm(client.business_name);
    setShowSuggestions(false);
    handleClientSelect(client.id);
  };

  // Close suggestions when clicking outside
  useEffect(() => {
    const handleClickOutside = () => setShowSuggestions(false);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const generateReports = async () => {
    setLoading(true);
    const generatedReports = [];

    try {
      for (const asset of selectedAssets) {
        const hailData = await fetchHailDataForLocation(
          asset.lat,
          asset.lng,
          new Date(dateRange.start),
          new Date(dateRange.end),
          radius
        );

        // Filter data maintaining the desired order
        const filteredData = {
          sightings: selectedSources.sightings ? hailData.sightings : [],
          noaa: selectedSources.noaa ? hailData.noaa : [],
          radar: selectedSources.radar ? hailData.radar : []
        };

        const report = generateHailReport(asset, filteredData);
        generatedReports.push(report);
      }

      setReports(generatedReports);
    } catch (error) {
      console.error('Error generating reports:', error);
    } finally {
      setLoading(false);
    }
  };

  // Add this function to prepare CSV data
  const prepareCSVData = () => {
    const csvData = reports.flatMap(report => 
      report.events.map(event => ({
        Address: report.assetInfo.address,
        Date: formatReportDate(event.BEGIN_DATE_TIME),
        Size: event.MAGNITUDE || event.MAXSIZE || event.SizeInInches || 'N/A',
        Source: event.source,
        Latitude: event.BEGIN_LAT || event.Lat || event.LAT,
        Longitude: event.BEGIN_LON || event.Lon || event.LON
      }))
    );

    return csvData;
  };

  // Add this function to handle CSV download
  const handleDownloadCSV = () => {
    const csvData = prepareCSVData();
    const headers = Object.keys(csvData[0]);
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => headers.map(header => row[header]).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `hail-report-${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="report-generator">
      <h2>Hail Report Generator</h2>

      <div className="report-controls">
        <div className="select-client">
          <label>Select Client:</label>
          <div className="autocomplete-wrapper">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search clients..."
              onClick={(e) => {
                e.stopPropagation();
                setShowSuggestions(true);
              }}
            />
            {showSuggestions && filteredClients.length > 0 && (
              <div className="suggestions-list">
                {filteredClients.map(client => (
                  <div
                    key={client.id}
                    className="suggestion-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSuggestionClick(client);
                    }}
                  >
                    {client.business_name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="search-params">
          <div className="radius-control">
            <label>Radius (miles):</label>
            <select
              value={radius / 1609.34} // Convert meters to miles for display
              onChange={(e) => setRadius(e.target.value * 1609.34)} // Convert miles to meters for storage
            >
              <option value="1">1 mile</option>
              <option value="2">2 miles</option>
              <option value="5">5 miles</option>
              <option value="10">10 miles</option>
            </select>
          </div>

          <div className="time-control">
            <label>Look Back:</label>
            <div className="time-input-wrapper">
              <div className="number-input">
                <input
                  type="number"
                  min="1"
                  max={timeFrame.unit === 'days' ? 31 : 10}
                  value={timeFrame.value}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    const maxValue = timeFrame.unit === 'days' ? 31 : 10;
                    if (!isNaN(value) && value >= 1 && value <= maxValue) {
                      setTimeFrame({
                        ...timeFrame,
                        value: value
                      });
                    }
                  }}
                />
              </div>
              <div id="lookback-control" className="segmented-control">
                <button
                  type="button"
                  className={timeFrame.unit === 'days' ? 'active' : ''}
                  onClick={() => setTimeFrame(prev => ({
                    unit: 'days',
                    value: Math.min(prev.value, 31)
                  }))}
                >
                  Days
                </button>
                <button
                  type="button"
                  className={timeFrame.unit === 'years' ? 'active' : ''}
                  onClick={() => setTimeFrame(prev => ({
                    unit: 'years',
                    value: Math.min(prev.value, 10)
                  }))}
                >
                  Years
                </button>
              </div>
            </div>
          </div>

          <div className="sources-control">
            <label>Data Sources:</label>
            <div className="source-checkboxes">
              {Object.entries(selectedSources).map(([source, isSelected]) => (
                <label key={source} className="source-checkbox">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => setSelectedSources(prev => ({
                      ...prev,
                      [source]: !prev[source]
                    }))}
                  />
                  {source.charAt(0).toUpperCase() + source.slice(1)}
                </label>
              ))}
            </div>
          </div>
        </div>

        <div className="date-range">
          <label>Date Range:</label>
          <input
            type="date"
            value={dateRange.start}
            onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
          />
          <input
            type="date"
            value={dateRange.end}
            onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
          />
        </div>

        <div className="button-group">
          <button 
            onClick={generateReports}
            disabled={!selectedClient || loading}
          >
            {loading ? 'Generating...' : 'Generate Reports'}
          </button>
          
          {reports.length > 0 && (
            <button 
              onClick={handleDownloadCSV}
              className="download-button"
            >
              Download CSV
            </button>
          )}
        </div>
      </div>

      <div className="reports-section">
        {reports.map((report, index) => (
          <div key={index} className="report-card">
            <h3>Report for {report.assetInfo.address}</h3>
            <div className="report-stats">
              <p>Total Events: {report.statistics.totalEvents}</p>
              <p>Largest Spotted Hail: {report.statistics.largestSpottedHail} inches</p>
              <p>Events by Source:</p>
              <ul>
                {Object.entries(report.statistics.eventsBySource).map(([source, count]) => (
                  <li key={source}>{source}: {count}</li>
                ))}
              </ul>
            </div>
            <table className="events-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Size</th>
                  <th>Source</th>
                </tr>
              </thead>
              <tbody>
                {report.events.map((event, eventIndex) => (
                  <tr key={eventIndex}>
                    <td>{formatReportDate(event.BEGIN_DATE_TIME)}</td>
                    <td>{event.MAGNITUDE || event.MAXSIZE || event.SizeInInches || 'N/A'} inches</td>
                    <td>{event.source}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReportGenerator;