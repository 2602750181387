import React, { useState } from 'react';

function PasswordForm({ onAuthenticate }) {
  const [password, setPassword] = useState('');

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'HailYeah!!') {
      onAuthenticate();
      localStorage.setItem('lastAuthTime', Date.now().toString());
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <form onSubmit={handlePasswordSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2>Enter Password to View Storm Events</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ margin: '10px 0', padding: '5px' }}
        />
        <button type="submit" style={{ padding: '5px 10px' }}>Submit</button>
      </form>
    </div>
  );
}

export default PasswordForm;