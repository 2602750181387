import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { supabaseUrl, supabaseKey } from '../../constants/apiConstants';
import './AdminConfig.css';

const supabase = createClient(supabaseUrl, supabaseKey);

function AdminConfig() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [searchRadius, setSearchRadius] = useState(10);
  const [results, setResults] = useState(null);

  const handleTriggerAutoReport = async () => {
    try {
      setLoading(true);
      setMessage('Triggering auto report...');
      
      const response = await fetch('https://auto.ionws.com/webhook/ajaxautoreport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setResults(data);
      setMessage('Successfully triggered auto report');

    } catch (error) {
      setMessage(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-config">
      <h2>Auto Report Processing</h2>
      
      <div className="config-section">
        <label>
          Search Radius (miles):
          <input
            type="number"
            value={searchRadius}
            onChange={(e) => setSearchRadius(Number(e.target.value))}
            min="1"
            max="100"
          />
        </label>
      </div>

      <div className="admin-actions">
        <button 
          onClick={handleTriggerAutoReport} 
          className="function-button" 
          disabled={loading}
        >
          Trigger Auto Report
        </button>
      </div>

      {message && <div className="message">{message}</div>}

      {results && (
        <div className="results-section">
          <h3>Processing Results</h3>
          {results.map((result, index) => (
            <div key={index} className="result-card">
              <h4>{result.client_name}</h4>
              <p>
                {result.address}, {result.city}, {result.state}
              </p>
              <div className="report-summary">
                <strong>Summary:</strong>
                <ul>
                  <li>Total Storm Events: {result.report_summary.total_storm_events}</li>
                  <li>Total Hail Sightings: {result.report_summary.total_hail_sightings}</li>
                  <li>Closest Event: {result.report_summary.closest_event_miles.toFixed(2)} miles</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AdminConfig;